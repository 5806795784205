import React, { useEffect, useState } from 'react';
import axios from 'axios';
// import { serverbaselink, instapikey } from '../Env_Variables';
import { instapikey } from '../../Env_Variables';

const axiosheaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

const serverbaselinkfunc = () => {
    var serverbaselinktemp = 'https://tejarrtech.com';

    return serverbaselinktemp;
};
const addinstpaikeytoobject = (payload) => {
    var newpayload = { ...payload };
    if (newpayload != undefined) {
        newpayload.instapikey = instapikey;
    } else {
        newpayload = { instapikey: instapikey };
    }

    return newpayload;
};

export const fetchAuthorization_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/vendor/webapp/fetchVendor_Authorization',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const fetchTabexCountriesStates_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/customer/webapp/fetchTabexCountriesStates',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const FetchVendorOrderHistory_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/vendor/webapp/FetchVendorOrderHistory',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};

export const VendorSignup_API = (axiosdata) => {
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/vendor/webapp/vendor_signup',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Login_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/vendor/webapp/login_vendor',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
export const Logout_API = (axiosdata) => {
    axios.defaults.withCredentials = true;
    axiosdata = addinstpaikeytoobject(axiosdata);
    const axiosfetch = axios({
        method: 'post',
        url: serverbaselinkfunc() + '/vendor/webapp/logout_vendor',
        headers: axiosheaders,
        data: axiosdata,
    });
    return axiosfetch;
};
