import React, { useEffect, useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Contexthandlerscontext } from './Contexthandlerscontext';
import { fetchAuthorization_API, Logout_API, Login_API } from './components/API/API';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { NotificationManager } from 'react-notifications';
import { LanguageContext } from './LanguageContext';
import Cookies from 'universal-cookie';
import { instapikey } from './Env_Variables';
export const Routedispatcher = React.createContext();
export const Routedispatcherprovider = (props) => {
    let history = useHistory();
    const queryClient = useQueryClient();
    const { lang, setlang, langdetect } = React.useContext(LanguageContext);
    const { fetchAuthorizationQueryContext } = useContext(Contexthandlerscontext);
    const [showcartslidercontext, setshowcartslidercontext] = useState(false);
    const [favoriteprojectscountContext, setfavoriteprojectscountContext] = useState([]);
    useEffect(() => {
        const cookies = new Cookies();
        var favoriteproducts = cookies.get('favoriteproducts');
        if (favoriteproducts == undefined) {
            setfavoriteprojectscountContext([]);
        } else {
            setfavoriteprojectscountContext(favoriteproducts.products);
        }
    }, []);
    const routedispatchercontext = (route) => {
        history.push(route);
    };
    const LogoutMutationContext = useMutation('Logout_API', {
        mutationFn: Logout_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error Logging Out.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                fetchAuthorizationQueryContext.refetch();
                routedispatchercontext('/vendor/login');
                NotificationManager.success('', lang.loggedoutsuccess);
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });
    const LoginMutationContext = useMutation('Login_API', {
        mutationFn: Login_API,
        onMutate: (variables) => {},
        onError: (error, variables, context) => {
            NotificationManager.error('', 'Error Logging In.');
        },
        onSuccess: (data, variables, context) => {
            if (data.data.status) {
                fetchAuthorizationQueryContext.refetch();

                routedispatchercontext('/vendor/home');

                NotificationManager.success('', lang.loggedinsuccess);
            } else {
                NotificationManager.warning('', data.data.reason);
            }
        },
    });

    return (
        <Routedispatcher.Provider
            value={{
                routedispatchercontext,
                LogoutMutationContext,
                LoginMutationContext,
                favoriteprojectscountContext,
                setfavoriteprojectscountContext,
                showcartslidercontext,
                setshowcartslidercontext,
            }}
        >
            {props.children}
        </Routedispatcher.Provider>
    );
};
