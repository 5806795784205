import React, { Component } from 'react';
import Cookies from 'universal-cookie';
export const LanguageContext = React.createContext();
export class LanguageProvider extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lang: {
                en: {
                    tejarrtech: 'Tejarrtech',
                    // Sort
                    hassale: 'Has sale',
                    sortby: 'Sort by',
                    latestarrivals: 'Latest Arrivals',
                    fromhightolow: 'Price: From high to low',
                    fromlowtohigh: 'Price: From low to high',
                    priceperone: 'Price per one',

                    // Policies
                    returningpolicy: 'Returning Policy',
                    shippingpolicy: 'Shipping Policy',

                    // Pages
                    home: 'Home',
                    shop: 'Shop',
                    login: 'Login',
                    signup: 'Signup',
                    orderhistory: ' Orders',
                    wishlist: 'Wishlist',
                    policies: 'Policies',
                    logout: 'Logout',
                    mycart: 'My Cart',
                    accountinformation: 'Account Information',
                    previousorders: ' Orders',
                    mywishlist: 'My Wishlist',
                    checkout: 'Checkout',
                    collections: 'Collections',

                    // Home
                    newseasonlooks: 'NEW SEASON LOOKS',
                    fastshipping: 'Fast Shipping',
                    securepayment: 'Secure payment',
                    premiumquality: 'Premium Quality',
                    mostlovedcollections: 'Most-loved collections',
                    letfashiontalk: 'Let Fashion Talks',
                    browseour: 'Browse Our',
                    premiumproducts: 'Premium Products',
                    bestquality: 'Best Quality',
                    premiumpackaging: 'Premium Packaging',
                    shopforyou: 'Shop for you',
                    andyourfamily: '& your family',

                    // login
                    welcomeback: 'Welcome Back',
                    joinusnow: 'Join us now',
                    email: 'E-mail',
                    password: 'Password',
                    confirmpassword: 'Confirm Password',
                    forgotpassword: 'Forgot your password?',
                    login: 'Login',
                    signup: 'Signup',
                    newcustomer: 'New Customer?',
                    joinus: 'Join us now',
                    alreadyhaveaccount: 'Already have an account',
                    name: 'Name',
                    fname: 'First Name',
                    lname: 'Last Name',
                    phonenumber: 'Phone number',
                    choosecountry: 'Choose country',
                    choosecountryfirst: 'Please choose country first',
                    country: 'Country',
                    governoratestate: 'Governorate/State',
                    choosestatefirst: 'Please choose governorate/state',
                    choosegovernoratestate: 'Choose Governorate/State',
                    choosecity: 'Choose City',
                    city: 'City',
                    choose: 'Choose',
                    confirmorder: 'Confirm Order',

                    // General
                    noproductsfound: 'No products found',
                    brand: 'Brand',
                    agreeto: 'Agree to',
                    termsandconitions: 'Terms & Conditions',
                    total: 'Total',
                    viewcart: 'View Cart',
                    productname: 'Product Name',
                    price: 'Price',
                    quantity: 'Quantity',
                    havepromocode: 'Do you have promocode?',
                    subtotal: 'Sub Total',
                    discount: 'Discount',
                    personalinformation: 'Personal Information',
                    address: 'Address',
                    security: 'Security',
                    description: 'Description',
                    ordernumber: 'Order Number',
                    numberofproducts: 'Number of products',
                    shippingaddress: 'Shipping Address',
                    orderinvoice: 'Order Invoice',
                    shippingfees: 'Shipping fees',
                    paymentmethod: 'Payment Method',
                    cod: 'Cash on delivery',
                    onlinepayment: 'Credit card on delivery',
                    youmayalsolike: 'You may also like',
                    nopreviousorders: 'You have no previous orders',
                    wishlistempty: 'Your wishlist is empty',
                    hello: 'hello',
                    loggedinas: 'Logged in as',
                    addressinformation: 'Address Information',
                    // Online Payment
                    paymentcardinformation: 'Payment Card Information',
                    cardnumber: 'Card Number',
                    year: 'Year',
                    month: 'Month',
                    cvv: 'CVV',
                    nameoncard: 'Name On Card',
                    orderdetails: 'Order Details',
                    ourcollections: 'Our Collections',
                    allproducts: 'All Products',
                    letsshoptogether: "Let's shop together",
                    shopbycollection: 'Shop By Collection',

                    // Status
                    inprogress: 'In progress',
                    pending: 'Pending',
                    delivered: 'Delivered',
                    shipped: 'Shippped',

                    // buttons
                    addtocart: 'Add to cart',
                    removefromfavorites: 'Remove from List',
                    addtofavorites: 'Add to list',
                    addedtowishlist: 'Added to your wishlist',
                    productrmovedfromwishlist: 'Removed from your wishlist',
                    shopnow: 'Shop Now',
                    remove: 'Remove',
                    apply: 'Apply',
                    checkout: 'Checkout',
                    update: 'Update',
                    showmore: 'Show More',
                    showless: 'Show less',
                    clearfilter: 'Clear Filters',
                    filter: 'Filter',
                    loadmore: 'Load more',

                    // Disclaimers
                    select: 'Select',
                    nosearchresults: 'No search results',
                    emptyshoppingcart: 'Your shopping cart is empty',

                    egp: 'EGP',
                    loggedinsuccess: 'Logged in successfully',
                    loggedoutsuccess: 'Logged out successfully',
                    addedtocartsuccess: 'Item is added to your cart',
                    orderaddedsuccess: 'Order has been successfully added',
                    countryandstateupdatedsuccess: 'Address Info Has Been Updated',
                    signupsuccess: 'Welcome to tejarrtech',
                    accountupdated: 'Your account is updated',
                    fillthemissingfields: 'Please complete the missing fields',
                    filltherequiredfields: 'Please complete the required fields',
                    chooseproductoptions: 'Please choose options first',
                    choosequantity: 'Please choose quantity',
                    price: 'Price',
                    sizes: 'Sizes',
                    sizesproductinfo: 'Sizes in',
                    numberofpieces: 'Number of pieces in',
                    shippingtime: 'Delivery is within 7 days',
                    pricesvat: 'All prices includes VAT',
                    vendortype: 'Vendor Type',
                },

                ar: {
                    tejarrtech: 'تجارتك',
                    // Sort
                    hassale: 'خصومات',
                    sortby: 'الترتيب حسب',
                    latestarrivals: 'احدث المنتجات',
                    fromhightolow: 'السعر: من الأعلى إلى الأقل',
                    fromlowtohigh: 'السعر: من الأقل إلى الأعلى',
                    priceperone: 'السعر للواحدة',

                    // Policies
                    returningpolicy: 'سياسة الإسترجاع',
                    shippingpolicy: 'سياسة الشحن',

                    // Pages
                    home: 'الصفحة الرئيسية',
                    shop: 'تسوق',
                    login: 'تسجيل الدخول',
                    signup: 'إنشاء حساب ',
                    orderhistory: 'الطلبات ',
                    wishlist: 'المفضلات',
                    policies: 'السياسات',
                    logout: 'خروج',
                    mycart: 'سلة التسوق',
                    accountinformation: 'البيانات الشخصية',
                    previousorders: 'الطلبات ',
                    mywishlist: 'مفضلاتى',
                    checkout: 'الدفع',
                    collections: 'المجموعات',

                    // Home
                    newseasonlooks: 'احدث إصدارات الموسم',
                    fastshipping: 'توصيل سريع',
                    securepayment: 'دفع امن',
                    premiumquality: 'افضل جودة',
                    mostlovedcollections: 'أكثر المجموعات المحبوبة',
                    letfashiontalk: 'دعنا نترك الموضة تتحدث',
                    browseour: 'تصفح',
                    premiumproducts: 'اجود المنتجات',
                    bestquality: 'اجود الخامات',
                    premiumpackaging: 'افضل تغليف',
                    shopforyou: 'تسوق لك',
                    andyourfamily: 'و لعائلتك',

                    // login
                    welcomeback: 'اهلا بك',
                    joinusnow: 'إنضم إلينا الان',
                    email: 'البريد الإلكترونى',
                    password: 'كلمة السر',
                    confirmpassword: 'تأكيد كلمة السر ',
                    forgotpassword: 'هل نسيت كلمة السر؟',
                    login: 'تسجيل الدخول',
                    signup: 'إنشاء حساب بائع جديد',
                    newcustomer: 'بائع جديد؟',
                    vendor: 'بائع ',
                    joinus: 'إنضم إلينا الان',
                    alreadyhaveaccount: 'هل لديك حساب بائع',
                    name: 'الإسم',

                    fname: 'الاسم الاول',
                    lname: 'الاسم الثاني',
                    phonenumber: 'رقم الهاتف',
                    choosecountry: 'إختر البلد',
                    choosecountryfirst: 'من فضلك إختر البلد اولا',
                    country: 'البلد',
                    governoratestate: 'المحافظة/الولاية',
                    choosegovernoratestate: 'إختر المحافظة/الولاية',
                    choosestatefirst: 'من فضلك إختر المحافظة/الولاية اولا',
                    choosecity: 'إختر المدينة',
                    city: 'المدينة',
                    choose: 'إختر',
                    confirmorder: 'تأكيد الطلب',
                    companyname: 'اسم الشركة',
                    brandname: 'اسم البراند',

                    companytaxesnumber: 'رقم التسجيل الضريبي',
                    companypapernumber: 'رقم السجل التجاري',
                    ssnnumber: 'الرقم القومي ',
                    bankaccountnumber: 'رقم الحساب البنكي للشركة',

                    // General
                    noproductsfound: 'لا توجد منتجات',
                    brand: 'الماركة',
                    agreeto: 'اوافق على',
                    termsandconitions: 'الشروط و الاحكام',
                    sizes: 'المقاسات',
                    sizesproductinfo: ' المقاسات في',
                    numberofpieces: 'عدد القطع فى',
                    total: 'المبلغ الكلى',
                    viewcart: 'سلة التسوق',
                    productname: 'إسم المنتج',
                    price: 'السعر',
                    quantity: 'الكمية',
                    havepromocode: 'هل لديك كود خصم؟',
                    subtotal: 'المبلغ الجزئى',
                    discount: 'الخصم',
                    personalinformation: 'البيانات الشخصية',
                    address: 'العنوان',
                    security: 'الخصوصية',
                    description: 'التفاصيل',
                    ordernumber: 'رقم الطلب',
                    numberofproducts: 'عدد المنتجات',
                    shippingaddress: 'عنوان ',
                    orderinvoice: 'الفاتورة',
                    shippingfees: 'مصاريف الشحن',
                    paymentmethod: 'طريقة الدفع',
                    cod: 'الدفع عند الإستلام',
                    onlinepayment: 'دفع بكارت ائتماني عند الاستلام',
                    youmayalsolike: 'يمكن ان يعجبك ايضا',
                    nopreviousorders: 'لا يوجد طلبات سابقة',
                    wishlistempty: 'لا يوجد مفضلات',
                    hello: 'اهلا',
                    loggedinas: 'البيانات المسجلة',
                    addressinformation: 'العنوان',
                    // Online Payment
                    paymentcardinformation: 'بيانات البطاقة',
                    cardnumber: 'رقم البطاقة',
                    year: 'السنة',
                    month: 'الشهر',
                    cvv: 'CVV',
                    nameoncard: 'الإسم على البطاقة',
                    orderdetails: 'تفاصيل الطلب',
                    ourcollections: 'مجموعاتنا',
                    allproducts: 'جميع المنتجات',
                    letsshoptogether: 'هيا نتسوق معا',
                    shopbycollection: 'تسوق المجموعات',

                    // Status
                    inprogress: 'جارى',
                    pending: 'على الإنتظار',
                    delivered: 'تم التوصيل',
                    shipped: 'تم الشحن',

                    // buttons
                    addtocart: 'اضف لسلة التسوق',
                    removefromfavorites: 'احذف من المفضلات',
                    addtofavorites: 'اضف للمفضلات',
                    addedtowishlist: 'الي المفضلات',
                    productrmovedfromwishlist: 'حذفت من المفضلات',
                    shopnow: 'تسوق الان',
                    remove: 'حذف',
                    apply: 'تطبيق',
                    checkout: 'الدفع',
                    update: 'تحديث',
                    showmore: 'المزيد',
                    showless: 'غلق',
                    clearfilter: 'حذف الإختيارات',
                    filter: 'فلترة',
                    loadmore: 'المزيد',

                    // Disclaimers
                    select: 'إختر',
                    nosearchresults: 'لا توجد نتائج',
                    emptyshoppingcart: 'سلة التسوق فارغة',

                    egp: 'ج.م ',
                    loggedinsuccess: 'تم تسجيل الدخول',
                    loggedoutsuccess: ' تم تسجيل الخروج',
                    addedtocartsuccess: 'تم إضافة المنتج',
                    orderaddedsuccess: 'تم عمل الطلب بنجاح  ',
                    countryandstateupdatedsuccess: 'تم تغيير العنوان ',
                    signupsuccess: 'اهلا بك فى تچارتك ',
                    accountupdated: 'تم تحديث البيانات',
                    fillthemissingfields: 'من فضلك اكمل باقى البيانات',
                    filltherequiredfields: 'من فضلك اكمل البيانات الإجبارية',
                    chooseproductoptions: 'من فضلك إختر من الإختيارات',
                    choosequantity: 'نت فضلك إختر الكمية',
                    price: 'السعر',
                    shippingtime: 'التوصيل فى خلال 7 ايام',
                    pricesvat: 'جميع الاسعار شاملة الضريبة',
                    vendortype: 'طريقة التعامل',
                },
            },
            langdetect: '',
            setlang: this.setlang,
        };
    }

    setlang = (key) => {
        const cookies = new Cookies();
        cookies.set('sitelang', key, { path: '/' });
        this.setState({
            langdetect: key,
        });
        window.location.reload();
    };

    componentDidMount() {
        const cookies = new Cookies();
        var lastlangselected = cookies.get('sitelang');
        if (lastlangselected == 'en') {
            // this.setState({
            //     langdetect: 'en',
            // });
            this.setState({
                langdetect: 'ar',
            });
        } else if (lastlangselected == 'ar') {
            this.setState({
                langdetect: 'ar',
            });
        } else {
            this.setState({
                langdetect: 'ar',
            });
        }
    }

    render() {
        var setlang = this.state.setlang;
        var lang = '';

        if (this.state.langdetect == 'en') {
            lang = this.state.lang.en;
            document.body.setAttribute('dir', 'ltr');
        } else if (this.state.langdetect == 'ar') {
            lang = this.state.lang.ar;
            document.body.setAttribute('dir', 'rtl');
        }
        var langdetect = this.state.langdetect;
        return <LanguageContext.Provider value={{ lang, setlang, langdetect }}>{this.props.children}</LanguageContext.Provider>;
    }
}

export const LanguageContextConsumer = LanguageContext.Consumer;
