import React, { Suspense, useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory } from 'react-router-dom';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { Routedispatcherprovider } from './Routedispatcher';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './trans.css';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import './components/Dashboard/Generalfiles/CSS_GENERAL/bootstrap.css';
import { Contexthandlerscontext } from './Contexthandlerscontext';
import logo from './components/Dashboard/Generalfiles/images/logos/logo.png';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import ScrollToTop from './components/ScrollToTop';
const Dashboardrouter = React.lazy(() => import('./components/Dashboard/Dashboardrouter'));
const App = (props) => {
    let history = useHistory();
    const { fetchAuthorizationQueryContext } = useContext(Contexthandlerscontext);
    return (
        <Router>
            <ScrollToTop />
            {fetchAuthorizationQueryContext.isFetching && (
                <div style={{ height: '100vh', width: '100vw', overflow: 'hidden' }} class="d-flex justify-content-center m-auto">
                    <div class="row m-0 w-100 d-flex justify-content-center align-items-center flex-column">
                        <img src={logo} style={{ maxWidth: '15%', maxheight: '15%' }} />
                        <CircularProgress color="#ccc" width="50px" height="50px" duration="1s" />
                    </div>
                </div>
            )}
            {fetchAuthorizationQueryContext.isSuccess && (
                <Routedispatcherprovider>
                    <div style={{ height: '100%', width: '100%' }}>
                        <Suspense
                            fallback={
                                <div
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        position: 'absolute',
                                        backgroundColor: 'white',
                                    }}
                                    class="row w-100 m-0 d-flex justify-content-center"
                                ></div>
                            }
                        >
                            <Route
                                render={({ location, match }) => {
                                    return (
                                        <div>
                                            <TransitionGroup>
                                                <CSSTransition in={match != null} key={location.key} timeout={300} classNames="page" unmountOnExit>
                                                    <Switch key={location.key} location={location}>
                                                        <Route
                                                            exact
                                                            path="/"
                                                            render={() => {
                                                                return <Redirect to="/vendor" />;
                                                            }}
                                                        />
                                                        <Route
                                                            path="/vendor"
                                                            render={() => {
                                                                return <Dashboardrouter />;
                                                            }}
                                                        />
                                                    </Switch>
                                                </CSSTransition>
                                            </TransitionGroup>
                                        </div>
                                    );
                                }}
                            />
                        </Suspense>
                    </div>
                </Routedispatcherprovider>
            )}
        </Router>
    );
};

export default App;
