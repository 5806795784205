import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import generalstyles from './Dashboard/Generalfiles/CSS_GENERAL/general.module.css';
export default function ScrollToTop(props) {
    const { pathname } = useLocation();
    // const setpageinnerclass = props.setpageinnerclassprops;
    useEffect(() => {
        document.querySelector('body').scrollTo(0, 0);
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}
